<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">JOBS</h1>
      <router-link
        to="/addjobs"
        class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
        ><i class="fas fa-plus fa-sm text-white-50"></i>Add Jobs</router-link
      >
    </div>
    <div class="bg-white border rounded">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Job Title</th>
            <th scope="col">Deadline</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in jobs" :key="job._id">
            <td>{{ job.title }}</td>
            <td>{{ moment(job.deadline).format("DD MMMM YYYY") }}</td>
            <td>
              <router-link
                :to="`/editjobs/${job._id}`"
                class="btn btn-warning mr-2"
                >Edit</router-link
              ><button
                @click.prevent="deleteJob(job._id)"
                class="btn btn-danger mr-2"
              >
                Delete
              </button>
              <button
                @click.prevent="archive(job._id)"
                class="btn"
                :class="{
                  'btn-info': job.archived,
                  'btn-success': !job.archived,
                }"
              >
                {{ job.archived ? "Unarchive" : "Archive" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
import moment from "moment";

export default {
  name: "Jobs",
  data() {
    return {
      jobs: [],
      moment,
    };
  },
  methods: {
    async getJobs() {
      try {
        const res = await axios.get("/api/v1/jobs?all=true");
        if (res.status === 200) {
          this.jobs = res.data.jobs;
        }
      } catch (e) {
        //   ---------------- HANDLE ERROR
        console.log(e);
      }
    },
    async deleteJob(id) {
      try {
        if (confirm("Are you sure?")) {
          const res = await axios.delete(`/api/v1/jobs/${id}`, {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          });
          this.getJobs();
        }
      } catch (e) {
        // ------ HANDLE ERROR
        console.log(e);
      }
    },
    async archive(id) {
      if (confirm("Are you sure?")) {
        const res = await axios.get(`/api/v1/jobs/${id}/archive`, {
          headers: {
            authorization: `bearer ${this.$store.state.token}`,
          },
        });

        if (res.status === 200) {
          this.getJobs();
        }
      }
    },
  },
  mounted() {
    this.getJobs();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>